export default {
    filterNoseDatas: [
        {
            id: "all",
            text: "Toutes les plaquettes"
        },
        {
            id: "integrated",
            text: "Plaquettes intégrées, non ajustables"
        },
        {
            id: "silicon",
            text: "Plaquettes silicones ajustables"
        }
    ]
}