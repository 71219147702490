export default {
    arrayMenu: [
        {
            txt: 'SOLAIRE',
            txt_en: 'SOLAR',
            link: 'solar'
        },
        {
            txt: 'OPTIQUE',
            txt_en: 'OPTIC',
            link: 'optic'
        },
        // {
        //     txt: 'COLLABORATIONS',
        //     link: 'collaborations'
        // },
        {
            txt: 'ANTI-LUMIERE BLEUE',
            txt_en: 'bluelight',
            link: 'bluelight'
        },
        {
            txt: 'GAMING',
            txt_en: 'GAMING',
            link: 'gaming'
        },
    ]
}