export default {
    filterSortDatas: [
        {
            id: "default",
            text: "Par défaut"
        },
        {
            id: "best_sellers",
            text: "Nos meilleures ventes"
        },
        {
            id: "news",
            text: "Nouveautés"
        },
        {
            id: "price_asc",
            text: "Prix croissant"
        },
        {
            id: "price_desc",
            text: "Prix Décroissant"
        }
       
    ]
}