<template>
    <div class="filters">
        <div class="all filter">
            <div class="selected" @click="displayPopup(true)">
                <span v-if="selectedShapeFilter!='all' ||
                    selectedGenderFilter!='unisex' ||
                    selectedColorFilter!='all' ||
                    selectedNoseFilter!='all' ||
                    selectedPriceFilter!='all' ||
                    selectedMaterialFilter!='all'">

                    {{resultsFilter}} RESULTAT(S)
                </span>
                <span v-else>TOUS LES FILTRES</span> 
                <img class="arrow" src="../../../../public/assets/images/arrow.svg" width="7" />
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: "CollectionFilters",
    components: {
       
    },
    props: ["resultsFilter","selectedShapeFilter","selectedSortFilter","selectedGenderFilter","selectedColorFilter","selectedPriceFilter","selectedNoseFilter","selectedMaterialFilter"],
    methods:{
        displayPopup(shapes=false) {
            this.$emit('displayPopupFilterEv',shapes)
        }
    },
    data(){
        return{
            loop: 3
        }
    }
}
</script>

<style scoped>
.filters .filter .arrow {
    position:absolute;
    right:10px;
    top:50%;
    transform: translateY(-50%);
}
.filters .filter {
    margin-left:10px;
    position:relative;
    overflow:hidden;
    margin-right:10px;
    border-radius:32px;
    border:1px solid grey;
    padding:10px 30px 10px 20px;
    cursor: pointer;
}
.filters {
    margin-top:30px;
    margin-bottom:30px;
    display:flex;
    align-content: center;
    justify-content: center;
}
</style>