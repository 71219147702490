export default {
    filterMaterialDatas: [
        {
            id: "all",
            text: "Tous les matériaux"
        },
        {
            id: "plastique",
            text: "Branches plastique"
        },
        // {
        //     id: "metal",
        //     text: "Branches métal"
        // }
    ]
}