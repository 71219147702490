<template>

<div class="collection-filter" v-if="loaded" :class="{one:filterNumber==1,two:filterNumber==2,three:filterNumber==3,four:filterNumber==4,five:filterNumber==5}">
    <div class="collection-filter-border"></div>
    <template v-for="item in arrayMenu" :key="item.link">
        <template v-if="item.link=='optic' && displayOptic==true">
            <div v-if="language=='fr'" class="item" :class="{active:selectedFilter==item.link}" @click="filterCollection(item.link)" >{{ item.txt }}</div>
            <div v-else class="item" :class="{active:selectedFilter==item.link}" @click="filterCollection(item.link)" >{{ item.txt_en }}</div>
        </template>
        <template v-else-if="item.link=='bluelight' && displayBluelight==true">
            <div v-if="language=='fr'" class="item" :class="{active:selectedFilter==item.link}" @click="filterCollection(item.link)" >{{ item.txt }}</div>
            <div v-else class="item" :class="{active:selectedFilter==item.link}" @click="filterCollection(item.link)" >{{ item.txt_en }}</div>
        </template>
           <template v-else-if="item.link=='gaming' && displayGaming==true">
            <div v-if="language=='fr'" class="item" :class="{active:selectedFilter==item.link}" @click="filterCollection(item.link)" >{{ item.txt }}</div>
            <div v-else class="item" :class="{active:selectedFilter==item.link}" @click="filterCollection(item.link)" >{{ item.txt_en }}</div>
        </template>
        <template v-else-if="item.link!='optic' && item.link!='gaming'  && item.link!='bluelight'">
            <div v-if="language=='fr'" class="item" :class="{active:selectedFilter==item.link}" @click="filterCollection(item.link)" >{{ item.txt }}</div>
            <div v-else class="item" :class="{active:selectedFilter==item.link}" @click="filterCollection(item.link)" >{{ item.txt_en }}</div>
        </template>
       
    </template>
    
    <div v-if="beta==true" class="item"  :class="{active:selectedFilter=='community'}" @click="filterCollection('community')" >COMMUNITY PICKS</div>
    <div v-else class="item"  :class="{active:selectedFilter=='community'}" @click="$router.push({ name: 'collection-beta' })" >COLLECTION | <img src="../../../../public/assets/images/beta.png" class="beta-title2" /></div>

    <div class="bar" :style="'left:'+barPosition+'%;width:'+barWidth+'%;'"></div>
      </div>
  
</template>

<script>
import collection_menu from "../../../data/collection/collection_menu"

export default {
    name: "CollectionMenu",
    props: ["beta","language","loaded", "barPosition", "barWidth", "selectedFilter", "displayBluelight", "displayGaming", "displayOptic","filterNumber"],
    methods: {
        filterCollection(link)
        {
            if(link === 'collaborations') this.$router.push({ name: link });
            this.$emit('filterCollectionEv', link)
        }
    },
    data(){
        const {arrayMenu} = collection_menu
        return{
            arrayMenu
        }
    }

}
</script>

<style scoped>
.beta-title2
{
    display: inline-block;
    width:40px;
    margin-left:0;
}

.collection-filter .item
{
    width:33%;
    cursor:pointer;
    display:inline-block;
}
.collection-filter .bar
{
    transition:all 0.5s ease-in-out;
    width:33%;
    height:2px ;
    background:#000;
    position:absolute;
    bottom:0;
}
.collection-filter.five .collection-filter-border{
    width:100%;
}
.collection-filter.four .collection-filter-border{
    width:100%;
}
.collection-filter.three .collection-filter-border{
    width:75%;
}
.collection-filter.two .collection-filter-border{
    width:100%;
}
.collection-filter.one .collection-filter-border{
    width:33%;
}
.collection-filter-border {
    position:absolute;
    bottom:0;
    left:50%;
    transform: translateX(-50%);
    border-bottom:0.5px solid #808080;
    width:100%;
}
.collection-filter
{
    position:relative;
    font-size:18px;
    text-align: center;
    width:455px;
    margin-left:auto;
    margin-right:auto;
    
    padding-bottom:10px;
}
@media screen and (max-width:760px)
{
    .beta-title2
    {
        width:30px !important;
    }
}
</style>