export default {
    filterShapeDatas: [
        {
            id: "all",
            text: "Toutes les formes",
            img:""
        },
        {
            id: "pantos",
            text: "Pantos",
            img:"pantos.svg"
        },
        {
            id: "round",
            text: "Ronde",
            img:"round.svg"
        },
        {
            id: "oval",
            text: "Ovale",
            img:"oval.svg"
        },
        {
            id: "aviator",
            text: "Aviateur",
            img:"aviator.svg"
        },
        {
            id: "butterfly",
            text: "Papillon",
            img:"butterfly.svg"
        },
        {
            id: "rectangle",
            text: "Rectangle",
            img:"rectangle.svg"
        },
        {
            id: "square",
            text: "Carré",
            img:"face-full.svg"
        },
       
    ]
}