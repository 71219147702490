export default {
    filterGenderDatas: [
        {
            id: "unisex",
            text: "Unisexe"
        },
        {
            id: "men",
            text: "Homme"
        },
        {
            id: "women",
            text: "Femme"
        }
    ]
}