export default {
    filterPriceDatas: [
        {
            id: "all",
            text: "Tous les prix"
        },
        {
            id: "90",
            text: "90,00€"
        },
        {
            id: "110",
            text: "110,00€"
        },
        {
            id: "130",
            text: "130,00€"
        }
    ]
}