<template>
    <div class="popup-filters">
        <div class="content">
            <img src="../../../public/assets/images/cross.svg" class="cross" @click="hidePopupFilter" />
            <div class="main">
                <div class="filter-section">
                    <div class="title">TOUS LES FILTRES</div>
                    <div class="cat-select" @click="displayListCats=!displayListCats">
                        <span>{{selectedFilterText}}</span>
                        <div class="list-filter-cats" v-if="displayListCats">
                            <span @click="() => selectFilter('solar','Lunettes solaires')">Lunettes solaires</span> 
                            <span v-if="displayOptic" @click="() => selectFilter('optic','Lunettes optiques')">Lunettes optiques</span> 
                            <span v-if="displayBluelight" @click="() => selectFilter('bluelight','Lunettes optiques')">Anti-Lumière bleue</span> 
                            <span v-if="displayGaming" @click="() => selectFilter('gaming','Lunettes optiques')">Gaming</span> 
                        </div>
                        <img class="arrow" src="../../../public/assets/images/arrow.svg" width="7" />
                    </div>
                </div>
                <div class="filter-section">
                    <div class="label">
                    TRIER
                    </div>
                    <div v-for="filter_sort in filterSortDatas" :key="filter_sort.id" class="cat-filter" @click="selectSort(filter_sort.id)" :class="{active:selectedSortFilter==filter_sort.id}">{{filter_sort.text}}</div>
                </div>
                 <div class="filter-section">
                    <div class="label">
                    FORMES
                    </div>
                    <content-loader class="content-loader-wrapper"  v-if="loadingShapes" viewBox="0 0 476 35" primaryColor="#f3f3f3"
                        secondaryColor="#cccccc">
                        <rect x="0" y="0" rx="10" ry="10" width="470" height="20" />
                    </content-loader>
                    <template v-else>
                        <template v-for="filter_shape in filterShapeDatas" :key="filter_shape.id" >
                            <div v-if="filter_shape.id=='pantos' && displayPantos==true" class="cat-filter" :class="{filter_shape:filter_shape.img!='',active:selectedShapeFilter==filter_shape.id}"  @click="selectShape(filter_shape.id)" >
                                <img v-if="filter_shape.img!=''" :src="require('../../../public/assets/collectionglass/' + filter_shape.img)" class="shape-img" />
                                <span>{{filter_shape.text}}</span> 
                            </div>
                            <div v-else-if="filter_shape.id=='round' && displayRound==true" class="cat-filter" :class="{filter_shape:filter_shape.img!='',active:selectedShapeFilter==filter_shape.id}"  @click="selectShape(filter_shape.id)" >
                                <img v-if="filter_shape.img!=''" :src="require('../../../public/assets/collectionglass/' + filter_shape.img)" class="shape-img" />
                                <span>{{filter_shape.text}}</span> 
                            </div>
                            <div v-else-if="filter_shape.id=='oval' && displayOval==true" class="cat-filter" :class="{filter_shape:filter_shape.img!='',active:selectedShapeFilter==filter_shape.id}"  @click="selectShape(filter_shape.id)" >
                                <img v-if="filter_shape.img!=''" :src="require('../../../public/assets/collectionglass/' + filter_shape.img)" class="shape-img" />
                                <span>{{filter_shape.text}}</span> 
                            </div>
                            <div v-else-if="filter_shape.id=='aviator' && displayAviator==true" class="cat-filter" :class="{filter_shape:filter_shape.img!='',active:selectedShapeFilter==filter_shape.id}"  @click="selectShape(filter_shape.id)" >
                                <img v-if="filter_shape.img!=''" :src="require('../../../public/assets/collectionglass/' + filter_shape.img)" class="shape-img" />
                                <span>{{filter_shape.text}}</span> 
                            </div>
                            <div v-else-if="filter_shape.id=='butterfly' && displayButterfly==true" class="cat-filter" :class="{filter_shape:filter_shape.img!='',active:selectedShapeFilter==filter_shape.id}"  @click="selectShape(filter_shape.id)" >
                                <img v-if="filter_shape.img!=''" :src="require('../../../public/assets/collectionglass/' + filter_shape.img)" class="shape-img" />
                                <span>{{filter_shape.text}}</span> 
                            </div>
                            <div v-else-if="filter_shape.id=='rectangle' && displayRectangle==true" class="cat-filter" :class="{filter_shape:filter_shape.img!='',active:selectedShapeFilter==filter_shape.id}"  @click="selectShape(filter_shape.id)" >
                                <img v-if="filter_shape.img!=''" :src="require('../../../public/assets/collectionglass/' + filter_shape.img)" class="shape-img" />
                                <span>{{filter_shape.text}}</span> 
                            </div>
                            <div v-else-if="filter_shape.id=='square' && displaySquare==true" class="cat-filter" :class="{filter_shape:filter_shape.img!='',active:selectedShapeFilter==filter_shape.id}"  @click="selectShape(filter_shape.id)" >
                                <img v-if="filter_shape.img!=''" :src="require('../../../public/assets/collectionglass/' + filter_shape.img)" class="shape-img" />
                                <span>{{filter_shape.text}}</span> 
                            </div>
                            <div v-else-if="filter_shape.id!='square' && filter_shape.id!='rectangle' && filter_shape.id!='butterfly' && filter_shape.id!='aviator' && filter_shape.id!='oval' && filter_shape.id!='round' && filter_shape.id!='pantos'" class="cat-filter" :class="{filter_shape:filter_shape.img!='',active:selectedShapeFilter==filter_shape.id}"  @click="selectShape(filter_shape.id)" >
                                <img v-if="filter_shape.img!=''" :src="require('../../../public/assets/collectionglass/' + filter_shape.img)" class="shape-img" />
                                <span>{{filter_shape.text}}</span> 
                            </div>
                        </template>
                    </template>
                </div>
                <div class="filter-section">
                    <div class="label">
                    GENRE
                    </div>
                    <content-loader class="content-loader-wrapper"  v-if="loadingGenders" viewBox="0 0 476 35" primaryColor="#f3f3f3"
                        secondaryColor="#cccccc">
                        <rect x="0" y="0" rx="10" ry="10" width="470" height="20" />
                    </content-loader>
                    <template v-else>
                        <template v-for="filter_sort in filterGenderDatas" :key="filter_sort.id" >
                            <div v-if="filter_sort.id=='men' && displayMen==true" class="cat-filter" @click="selectGender(filter_sort.id)" :class="{active:selectedGenderFilter==filter_sort.id}">{{filter_sort.text}}</div>
                            <div v-else-if="filter_sort.id=='women' && displayWomen==true" class="cat-filter" @click="selectGender(filter_sort.id)" :class="{active:selectedGenderFilter==filter_sort.id}">{{filter_sort.text}}</div>
                            <div v-else-if="filter_sort.id=='unisex' && displayUnisex==true" class="cat-filter" @click="selectGender(filter_sort.id)" :class="{active:selectedGenderFilter==filter_sort.id}">{{filter_sort.text}}</div>
                            <div v-else-if="filter_sort.id!='women' && filter_sort.id!='men' && filter_sort.id!='unisex'" class="cat-filter" @click="selectGender(filter_sort.id)" :class="{active:selectedGenderFilter==filter_sort.id}">{{filter_sort.text}}</div>
                        </template>
                    </template>
                    
                </div>
                <div class="filter-section">
                    <div class="label">
                    COULEURS
                    </div>
                    <div class="cat-filter-color" v-for="color in colors" :key="color.id" @click="selectColor(color.id)" :class="{active:selectedColorFilter==color.id}"><div class="color-bg" :style="'background-color:'+color.code+';'"></div></div>
                </div>
                <div class="filter-section">
                    <div class="label">
                    PRIX
                    </div>
                    <div v-for="filter_sort in filterPriceDatas" :key="filter_sort.id" class="cat-filter" @click="selectPrice(filter_sort.id)" :class="{active:selectedPriceFilter==filter_sort.id}">{{filter_sort.text}}</div>
                </div>
                <div class="filter-section">
                    <div class="label">
                    MATERIAUX
                    </div>
                    <template v-for="filter_sort in filterMaterialDatas" :key="filter_sort.id" >
                        <div v-if="filter_sort.id=='metal' && displayMetal==true" class="cat-filter" @click="selectMaterial(filter_sort.id)" :class="{active:selectedMaterialFilter==filter_sort.id}">{{filter_sort.text}}</div>
                        <div v-else-if="filter_sort.id=='plastic' && displayPlastic==true" class="cat-filter" @click="selectMaterial(filter_sort.id)" :class="{active:selectedMaterialFilter==filter_sort.id}">{{filter_sort.text}}</div>
                        <div v-else-if="filter_sort.id!='metal' && filter_sort.id!='plastic'" class="cat-filter" @click="selectMaterial(filter_sort.id)" :class="{active:selectedMaterialFilter==filter_sort.id}">{{filter_sort.text}}</div>
                    </template>
                </div>
                <div class="filter-section">
                    <div class="label">
                    PLAQUETTES NEZ
                    </div>
                    <content-loader class="content-loader-wrapper"  v-if="loadingNoses" viewBox="0 0 476 35" primaryColor="#f3f3f3"
                        secondaryColor="#cccccc">
                        <rect x="0" y="0" rx="10" ry="10" width="470" height="20" />
                    </content-loader>
                    <template v-else>
                        <template v-for="filter_sort in filterNoseDatas" :key="filter_sort.id" >
                            <div v-if="filter_sort.id=='integrated' && displayIntegratedNose==true" class="cat-filter" @click="selectNose(filter_sort.id)" :class="{active:selectedNoseFilter==filter_sort.id}">{{filter_sort.text}}</div>
                            <div v-else-if="filter_sort.id=='silicon' && displaySiliconNose==true" class="cat-filter" @click="selectNose(filter_sort.id)" :class="{active:selectedNoseFilter==filter_sort.id}">{{filter_sort.text}}</div>
                            <div v-else-if="filter_sort.id!='silicon' && filter_sort.id!='integrated'" class="cat-filter" @click="selectNose(filter_sort.id)" :class="{active:selectedNoseFilter==filter_sort.id}">{{filter_sort.text}}</div>
                        </template>
                    </template>
                </div>
            </div>
            <div class="bottom-filters">
                <div class="button-left" @click="hidePopupFilter" >SHOW {{resultsFilter}} RESULT(S)</div>
                <div class="button-right" @click="clearFilters" >Clear All</div>
            </div>
        </div>
    </div>
</template>

<script>

import collection_menu from "../../data/collection/collection_menu"
import filterSort from "../../data/popup/filterSort";
import filterShape from "../../data/popup/filterShape";
import filterGender from "../../data/popup/filterGender";
import filterPrice from "../../data/popup/filterPrice";
import filterMaterial from "../../data/popup/filterMaterial";
import filterNose from "../../data/popup/filterNose";
import {APIRequest} from "@simb2s/senseye-sdk";
const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
import { ContentLoader } from 'vue-content-loader'
import { useSettingsStore } from '@/store/SettingsStore.js';

export default {
    
    name: "PopupFilterComponent",
    components: {
        ContentLoader
    },
    props:["selectedFilter","resultsFilter","selectedShapeFilter","selectedSortFilter","selectedGenderFilter","selectedColorFilter","selectedPriceFilter","selectedNoseFilter","selectedMaterialFilter", "displayBluelight", "displayGaming", "displayOptic"],
    methods: {
        async getColors() {
            let colors= await APIRequest.list('colors');
            this.colors=colors[0]
        },
        hidePopupFilter() {
            this.$emit('hidePopupFilterEv')
        },
        selectPrice(filter) {
            this.$emit('filterPriceEv',filter)
        },
        selectMaterial(filter) {
            this.$emit('filterMaterialEv',filter)
        },
        selectNose(filter) {
            this.$emit('filterNoseEv',filter)
        },
        selectSort(filter) {
            this.$emit('filterSortEv',filter)
        },
        selectShape(filter) {
            this.$emit('filterShapeEv',filter)
        },
        selectGender(filter) {
            this.$emit('filterGenderEv',filter)
        },
        selectColor(filter) {
            this.$emit('filterColorEv',filter)
        },
        selectFilter(filter,text) {
            this.selectedFilterText=text
            this.$emit('filterCollectionEv',filter)
        },
        clearFilters(){
            this.$emit('filterSortEv',"default")
            this.$emit('filterShapeEv',"all")
            this.$emit('filterGenderEv','unisex')
            this.$emit('filterColorEv',"all")
            this.selectedFilterText="Lunettes Solaires"
            this.$emit('filterCollectionEv','solar')
            this.$emit('filterPriceEv',"all")
            this.$emit('filterMaterialEv',"all")
            this.$emit('filterNoseEv',"all")
        }
    },
    
    data(){
        const {filterSortDatas} = filterSort;
        const {filterPriceDatas} = filterPrice;
        const {filterNoseDatas} = filterNose;
        const {filterMaterialDatas} = filterMaterial;
        const {filterShapeDatas} = filterShape;
        const {filterGenderDatas} = filterGender;
        const {arrayMenu} = collection_menu
     
        return{
            useSettings:useSettingsStore(),
            arrayMenu,
            filterSortDatas,
            filterShapeDatas,
            filterGenderDatas,
            filterPriceDatas,
            filterNoseDatas,
            filterMaterialDatas,
            colors:null,
            displayListCats:false,
            selectedFilterText:this.selectedFilter=='solar' ? "Lunettes solaires" : "Lunettes optiques",
            displayPantos:false,
            displayRound:false,
            displayOval:false,
            displayRectangle:false,
            displaySquare:false,
            displayButterfly:false,
            displayAviator:false,
            displayMen:false,
            displayWomen:false,
            displaySiliconNose:false,
            displayIntegratedNose:false,
            displayUnisex:false,
            displayPlastic:false,
            displayMetal:false,
            loadingMaterials:true,
            loadingNoses:true,
            loadingGenders:true,
            loadingShapes:true
        }
    },
    async mounted() {

        if(this.useSettings.settingsLoaded==false || this.useSettings.loadingNoses || this.useSettings.loadingGenders || this.useSettings.loadingShapes || this.useSettings.loadingMaterials) {
            await this.useSettings.loadSettings()
        }

        this.displayPantos=this.useSettings.displayPantos
        this.displayRound=this.useSettings.displayRound
        this.displayOval=this.useSettings.displayOval
        this.displayRectangle=this.useSettings.displayRectangle
        this.displaySquare=this.useSettings.displaySquare,
        this.displayButterfly=this.useSettings.displayButterfly
        this.displayAviator=this.useSettings.displayAviator
        this.displayMen=this.useSettings.displayMen
        this.displayUnisex=this.useSettings.displayUnisex
        this.displayWomen=this.useSettings.displayWomen
        this.displaySiliconNose=this.useSettings.displaySiliconNose
        this.displayIntegratedNose=this.useSettings.displayIntegratedNose
        this.displayMetal=this.useSettings.displayMetal
        this.displayPlastic=this.useSettings.displayPlastic
        
        this.loadingMaterials=this.useSettings.loadingMaterials
        this.loadingNoses=this.useSettings.loadingNoses
        this.loadingGenders=this.useSettings.loadingGenders
        this.loadingShapes=this.useSettings.loadingShapes

       
        await this.getColors()

        if(this.selectedFilter=='solar')
        {
            this.selectedFilterText="Lunettes solaires"
        }
        if(this.selectedFilter=='optic')
        {
            this.selectedFilterText="Lunettes optiques"
        }
        if(this.selectedFilter=='bluelight')
        {
            this.selectedFilterText="Anti-lumière bleue"
        }
        if(this.selectedFilter=='gaming')
        {
            this.selectedFilterText="Gaming"
        }

       


    }
}
</script>

<style scoped>
.cat-select .arrow {
    position:absolute;
    right:10px;
    top:15px;
    transform:translateY(-50%);
}
.list-filter-cats span {
    font-size:13px;
    width:100%;
    cursor: pointer;
}
.list-filter-cats span:hover {
    font-weight: 500;
}
.list-filter-cats {
    display: flex;
    margin-top:10px;
    flex-direction: column;
}
.cat-select {
    position:relative;
    padding:5px 25px 5px 10px;
    text-transform: uppercase;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    width:auto;
    background:rgba(118, 118, 128, 0.12);
    border-radius: 20px;
}
.cross {
    cursor: pointer;
    position:absolute;
    left:30px;
    top:10px;
    width:55px;
    z-index:2;
}



.cat-filter-color .color-bg {
    background:#000;
    width:30px;
    cursor:pointer;
    height:30px;
    position:absolute;
    left:2px;
    top:2px;
    border-radius: 50%;
    border:1px solid rgb(214, 214, 214);
}
.cat-filter-color.active {
    border:1px solid #000;
}
.cat-filter-color {
    position:relative;
    width:36px;
    height:36px;
    border-radius:50%;
    margin-right:10px;
    margin-top:10px;
    display: inline-block;
}
.main .title {
    font-size:22px;
}
@keyframes animationpopup{
    from{
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@keyframes animationpopupcontent{
    from{
        transform:translateX(100%);
    }
    to {
        transform:translateX(0);
    }
}
.popup-filters {
    position:fixed;
    left:0;
    animation: animationpopup 0.3s ease-in;
    top:0;
    z-index:999999;
    background:rgba(0,0,0,0.7);
    width: 100%;
    height:100%;
}
.popup-filters .content .main {
    position: relative;
    width:100%;
    height:calc(100% - 80px);
    overflow-y: auto;
    overflow-x:hidden;
    padding:50px;
}
.popup-filters .content {
    position:absolute;
    right:0;
    width: 40%;
    min-width: 400px;
    background:#fff;
    animation: animationpopupcontent 0.3s ease-out;
    height:100%;
}
.filter-section:first-child{
    border-top:0 !important;
}
.filter-section {
    padding-top:20px;
    margin-bottom:30px;
    border-top:1px solid rgb(216, 216, 216);
}
.cat-filter.active {
    color:#fff;
    background:#000;
}
.cat-filter {
    display:inline-block; margin-right:10px;
    padding:5px 10px;
    font-size:14px;
    border:1px solid #000;
    margin-top:10px;
    border-radius:20px;
    cursor: pointer;
    transition:all 0.3s;
    vertical-align: top;
}
.cat-filter.filter_shape span {
    position:relative;
    top:3px;
}
.cat-filter.filter_shape {
    padding:2px 10px 2px 2px;
    position:relative;
    overflow:hidden;
    display:inline-flex;
 
}
.cat-filter .shape-img {
    position:relative;
    width:50px;
    padding:6.5px;
    margin-right:5px;
    background:#fff;
    border-radius:20px;
    
}
.bottom-filters .button-left {
   font-weight: 300;
    font-size: 16px;
    color:#fff;
    background: #000;
    cursor: pointer;
  
    padding:15px 30px;
    border-radius:17px;
}
.bottom-filters .button-right {
   
    color:grey;
    cursor: pointer;
  font-weight: 300;
    font-size: 16px;
    padding:15px 30px;
    border-radius:32px;
}
.bottom-filters {
    position:absolute;
    bottom:0;
    background:#fff;
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items:center;
    padding:20px 50px;
    border-top:1px solid rgb(216, 216, 216);
}
</style>