<template>
          <div class="title-collection">COLLECTION &nbsp;&nbsp;| <img src="../../../../public/assets/images/beta.png" class="beta-title" /></div>
        <div class="collection-text" v-if="language=='fr'"><br /><br />Une sélection de lunettes entièrement personnalisables et imprimées en 3D.<br />
Toutes nos paires de lunettes sont fabriquées à la demande avec une politique de zéro stock et zéro déchet.
        </div>
        <div class="collection-text" v-else><br /><br />Browse a selection of 3D printed eyewear with unique customization options.<br />
Visages ensures that each pair of glasses is manufactured on-demand, with a zero stock & zero waste policy.
        </div>
        <br /><br />
</template>

<script>
import collectionGamingTxt from '../../../data/collection/collection_gaming_txt';

export default {
    name: "CollectionBetaHeader",
    props: ["language"],
    components: {},
    data(){
        const {txt} = collectionGamingTxt
        return{
            txt
        }
    }
}
</script>

<style scoped>

@media screen and (max-width:420px) {
    .beta-title {
    width:72px !important;
    margin-left:10px !important;
    }
}
@media screen  and (min-width:420px)  and (max-width:760px) {
    .beta-title {
    width:90px !important;
    margin-left:10px !important;
    }
}
.beta-title {
   display:inline-block;
   width:180px;
   margin-left:20px;
}
</style>